import React from 'react';
import '../Stylesheets/Button.css'; 

const CustomButton = ({ title }) => {
  return (
    <button className="styled-button fw-medium" style={{fontSize:'calc(0.7rem + 0.3vw)'}}>{title}</button>
  );
}

export default CustomButton;
