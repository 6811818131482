import React from "react";
import Navbar from "../Components/Common/Navbar";
import about_background from "../Images/2D/Hero.webp";
import img1 from "../Images/2D/Learning Representation 1.webp";
import img2 from "../Images/2D/Learning Representation 2.webp";
import "../Stylesheets/Publications.css";
import BackToTop from "../Components/Common/BackToTop";
import Footer from "../Pages/Footer.js";
import Breadcrumbs from "../Components/Common/Breadcrumbs";
import Hero from "../Components/Common/Hero.js";

import ReadMore from "../Components/Common/ReadMore.js";
import PublicationsFetch from "../Components/Publications/PublicationsFetchFunction.js";

function TwoDimensionalPage3() {
  return (
    <>
      <div className="min-vh-100" style={{ backgroundColor: "#F6F4F2" }}>
        <BackToTop />
        <Navbar />
        <br />
        <br />
        <br />

        <Hero
          title="Learning Representation of 2D Data"
          subTitle=""
          img={about_background}
          showAnimationText="false"
          creatorLink="https://unsplash.com/@pawel_czerwinski?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          creator="Pawel Czerwinski"
          imageLink="https://unsplash.com/photos/a-computer-generated-image-of-a-pink-and-purple-wave-7FqOISWr5V0?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          source="Unsplash"
        />

        <br />
        <div
          className="container"
          style={{ paddingBlock: "1em", marginTop: "1em" }}
        >
          <Breadcrumbs />
          <div className="row">
            <div className="container content-font-size">
              <ReadMore>
                Our research initiatives are centered on the development of
                highly efficient techniques for transforming 2D data into
                formats suitable for utilization in machine learning and deep
                learning models. This transformative process involves the
                extraction of pertinent features from raw data, which are
                subsequently used to construct feature vectors. These feature
                vectors are carefully designed to encapsulate the most relevant
                and discriminative information within the data. By doing so, we
                enable the efficient analysis and interpretation of this
                information, facilitating the application of powerful machine
                learning and deep learning algorithms for tasks such as image
                classification, object recognition, and image segmentation. Our
                work in this domain seeks to bridge the gap between raw data and
                machine intelligence, enabling more accurate and insightful
                automated decision-making processes.
              </ReadMore>
            </div>
          </div>
          <br />
          <div className="row">
            <div className=" col-lg col-md-6 p-4">
              <img src={img1} className="img-fluid" alt="..." />
            </div>
            {/*  </div>  */}
            <br />
            <br />
            {/* <div className="row"> */}
            <div className=" col-lg-6 col-md-6 p-4 ">
              <img src={img2} className="img-fluid" alt="..." />
            </div>
          </div>
        </div>

        {/* related publications code */}
        <br />
        <div
          className="text-center fw-bold heading-font-size"
          style={{
            paddingBlockEnd: "1em",
          }}
        >
          Related Publications
        </div>
        <PublicationsFetch researchFilter="Learning Representation of 2D Data (LR2D)" />
        {/* related publications code */}
        <Footer />
      </div>
    </>
  );
}

export default TwoDimensionalPage3;
