import React from "react";
import hero from "../Images/2D/Hero.webp";
import card1 from "../Images/2D/card1.webp";
import card2 from "../Images/2D/enhancement.webp";
import card3 from "../Images/2D/fish.webp";
import card4 from "../Images/2D/feature-extraction.webp";
import card5 from "../Images/2D/TSNE.webp";
import card6 from "../Images/2D/Image_quality_assessment.webp";

import Cards from "../Components/Research/Cards_main.js";
import Navbar from "../Components/Common/Navbar";
import BackToTop from "../Components/Common/BackToTop";
import Breadcrumbs from "../Components/Common/Breadcrumbs";

import Footer from "../Pages/Footer.js";
import Hero from "../Components/Common/Hero.js";

import ReadMore from "../Components/Common/ReadMore.js";

function TwoDimensional() {
  return (
    <>
      <div className="main pages_background  min-vh-100 w-100">
        <BackToTop />
        <Navbar />

        <br />
        <br />
        <br />

        <Hero
          title="2D Data Acquisition and Processing"
          subTitle=""
          img={hero}
          creatorLink="https://unsplash.com/@pawel_czerwinski?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          creator="Pawel Czerwinski"
          imageLink="https://unsplash.com/photos/a-computer-generated-image-of-a-pink-and-purple-wave-7FqOISWr5V0?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          source="Unsplash"
        />

        <div className="container">
          <div style={{ paddingBlock: "1em", marginTop: "1em" }}>
            <Breadcrumbs />

            <div className="content-font-size" style={{ textAlign: "justify" }}>
              <ReadMore>
                In the domain of 2D image acquisition and processing, our
                research primarily delves into image enhancement and
                restoration, with a specific focus on addressing challenges in
                low-light and underwater environments. The core objective
                revolves around the development of innovative imaging techniques
                to establish ground truth, serving as input for our ISP pipeline
                and facilitating enhancement and restoration frameworks across
                diverse imaging scenarios. Our current endeavors center on the
                proposition and design of pioneering deep generative algorithms,
                aiming to bolster image quality, precision, and efficiency.
                Moreover, our research extensively harnesses the capabilities of
                deep generative models, not only for image enhancement and
                denoising but also for data augmentation, thereby significantly
                refining the interpretability and quality of images captured in
                low-light conditions. The implications of our findings resonate
                across various domains, including but not limited to digital
                heritage preservation, agriculture, smart manufacturing, medical
                imaging, surveillance, remote sensing, computer vision, and
                robotics, underscoring the wide-ranging impact of our
                contributions.
              </ReadMore>
            </div>
          </div>

          <div style={{ backgroundColor: "", paddingBlock: "1em" }}>
            <div className="row gy-3 gy-md-4">
              <div className="col-lg-4 col-md-6 gx-md-4 gx-xxl-4 gx-0 justify-content-center">
                <Cards
                  id="01"
                  img={card1}
                  title="2D Data Acquisition / Capture Setup"
                  link="/Research/2d/Two_Dimensional_Page1"
                  // desc="Enabling the development of advanced 2D data capture setups. This involves selecting or designing the appropriate visual sensors, cameras, and imaging systems to ensure high-quality data acquisition. Factors such as resolution, frame rate, and sensor type are carefully considered to optimize data collection."
                />
              </div>

              <div className="col-lg-4 col-md-6 gx-md-4 gx-xxl-4 gx-0 justify-content-center">
                <Cards
                  id="02"
                  img={card2}
                  title="Image Restoration and Enhancement"
                  link="/Research/2d/Two_Dimensional_Page2"
                  // desc="Employ image restoration and enhancement techniques to improve the quality of acquired images. This includes denoising, deblurring, and contrast adjustment to remove artefacts and enhance the visual clarity of images. High-quality input data is crucial for accurate downstream processing."
                />
              </div>

              <div className="col-lg-4 col-md-6 gx-md-4 gx-xxl-4 gx-0 justify-content-center">
                <Cards
                  id="03"
                  img={card5}
                  title="Learning Representation of 2D Data"
                  link="/Research/2d/Two_Dimensional_Page3"
                  // desc="Developing effective methods for representing 2D data in a form suitable for machine learning and deep learning models. We extract features and create feature vectors that capture relevant information, enabling efficient analysis and interpretation."
                />
              </div>

              <div className="col-lg-4 col-md-6 gx-md-4 gx-xxl-4 gx-0 justify-content-center">
                <Cards
                  id="04"
                  img={card4}
                  title="Filtering, Classification, and Categorization of 2D Data"
                  link="/Research/2d/Two_Dimensional_Page4"
                  // desc="Apply filtering, classification, and categorization techniques to the processed 2D data. This enables the organization of data into meaningful groups, making it easier to extract insights and make decisions. Tasks such as object categorization and event recognition fall under this category."
                />
              </div>

              <div className="col-lg-4 col-md-6 gx-md-4 gx-xxl-4 gx-0 justify-content-center">
                <Cards
                  id="05"
                  img={card6}
                  title="Quality Analysis of Images"
                  link="/Research/2d/Two_Dimensional_Page5"
                  // desc="Ensuring the quality and reliability of acquired images is a key aspect of our research. We develop methods and metrics to assess image quality, including factors like sharpness, color fidelity, and noise levels. Quality analysis aids in identifying and addressing issues in the image acquisition process."
                />
              </div>

              <div className="col-lg-4 col-md-6 gx-md-4 gx-xxl-4 gx-0 justify-content-center">
                <Cards
                  id="06"
                  img={card3}
                  title="Detection, Localization, and Tracking"
                  link="/Research/2d/Two_Dimensional_Page6"
                  // desc="Design algorithms and models for the detection, localization, and tracking of objects or regions of interest within 2D visual data. This includes tasks like object recognition, spatial localization, and temporal tracking, which are fundamental for various applications, including computer vision and robotics."
                />
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default TwoDimensional;
