import React, { useContext } from "react";
import "../Stylesheets/Publications.css";
import BackToTop from "../Components/Common/BackToTop";
import ExcelDatabaseReader from "../Components/Publications/Category_filter";
// import categoryContext from "../Global varaibles/Category_filter/Category_context";
import Navbar from "../Components/Common/Navbar";
import YearFilter from "../Components/Publications/Year_filter";
import Paperfilter from "../Components/Publications/Paperfilter";
import yearContext from "../Global varaibles/Year_filter/Year_context";
import paperTypeContext from "../Global varaibles/PaperType_filter/PaperType_context";
import Footer from "../Pages/Footer.js";
import topbg from "../Images/Research/topbg.webp";
import Hero from "../Components/Common/Hero.js";
import PublicationsFetch from "../Components/Publications/PublicationsFetchFunction.js";

function Publications() {
  // const { category } = useContext(categoryContext);
  const { year } = useContext(yearContext);
  const { paperType } = useContext(paperTypeContext);

  // jQuery code for search input and PDF download

  return (
    <>
      <div className="main min-vh-100" style={{ backgroundColor: "#F6F4F2" }}>
        <BackToTop />
        <Navbar />
        <br />
        <br />
        <br />

        <Hero
          title="Publications"
          subTitle=""
          img={topbg}
          creatorLink="https://unsplash.com/@pawel_czerwinski?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          creator="Pawel Czerwinski"
          imageLink="https://unsplash.com/photos/background-pattern-QiQqHm8aaUM?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          source="Unsplash"
        />

        <div className="container text-center">
          <br />
          <br />
          <div className="row gy-1">
            {/* Search by Keywords */}
            <div className="col-md col-md-3 col-lg-3">
              <div className="input-group mb-0">
                <input
                  type="text"
                  className="form-control rounded-2 border-black fw-medium"
                  placeholder="Search by keywords"
                  id="myInput"
                />
              </div>
            </div>

            <div className="col-md-1 col-lg-1 col-xl-2"></div>
            {/* Paper Type Dropdown */}
            <div className="col-md col-md-3 col-lg-3 col-xl-2 mb-0">
              <div className="dropdown">
                <button
                  className="btn btn-light w-100 border-black  text-center dropdown-toggle overflow-hidden fw-medium"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {paperType ? paperType : "Paper Type (All)"}
                </button>
                <ul className="dropdown-menu" id="paper-dropdown">
                  <Paperfilter />
                </ul>
              </div>
            </div>

            {/* Year Dropdown */}
            <div className="col-lg-2 col-md-2 mb-0">
              <div className="dropdown">
                <button
                  className="yearbutton btn btn-light w-100 border-black text-center dropdown-toggle fw-medium"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {year ? year : "Year (All)"}
                </button>
                <ul
                  className="dropdown-menu"
                  id="year-dropdown"
                  style={{ maxHeight: "200px", overflow: "auto" }}
                >
                  <YearFilter />
                </ul>
              </div>
            </div>

            {/* Category Dropdown */}
            <div className="col-md col-md-3 col-lg-3 col-xl-2 mb-0">
              <ExcelDatabaseReader />

              {/* <div className="dropdown">
                <button
                  className="btn btn-light w-100 border-black shadow-sm text-center dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {category ? category : "Category (All)"}
                </button>
                <ul
                  className="dropdown-menu overflow-y-auto"
                  id="category-dropdown"
                  style={{ maxHeight: "200px" }}
                >
                  <ExcelDatabaseReader />
                </ul>
              </div> */}
            </div>
          </div>
        </div>

        <br />
        <div
          className="heading-font-size text-center fw-bold mt-4"
          style={{
            paddingBlockEnd: "1em",
            color: "black",
          }}
        >
          Recent Publications
        </div>

        <PublicationsFetch />
        <div className="mt-5">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Publications;
