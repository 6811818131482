import React, { useState, useEffect } from "react";
import "../Stylesheets/Home.css";
import "../index.css";
import Navbar from "../Components/Common/Navbar";
import { Link } from "react-router-dom";
import CarouselItem from "../Components/Home/CarouselItem.js";
// import HorizontalCard from "../Components/Home/HorizontalCard";
// import output_1 from "../Videos/main_video.mp4";
import output_2 from "../Videos/FinalWeb.mov";
import research_projects_1 from "../Images/Projects/SponsoredImage.webp";
import research_projects_2 from "../Images/Projects/CollaborativeImage.webp";
import research_projects_3 from "../Images/Projects/ConsultancyImage.webp";
import research3 from "../Images/Home/2Ddata.webp";
// import one from "../Images/Landing_images/one.webp";
// import two from "../Images/Landing_images/two.webp";
// import three from "../Images/Landing_images/three.webp";
// import four from "../Images/Landing_images/four.webp";
// import five from "../Images/Landing_images/five.webp";
// import six from "../Images/Landing_images/six.webp";
import gif1 from "../Images/Home/gif1.webp";
import gif2 from "../Images/Home/gif2.webp";
import gif3 from "../Images/Home/gif3.webp";
import gif4 from "../Images/Home/gif4.webp";
import gif5 from "../Images/Home/gif5.webp";
// import gif6 from "../Images/Home/hampi.webp";
import gif9 from "../Images/Home/underwater.webp";
import gif10 from "../Images/Home/gif10.webp";
import gif11 from "../Images/Home/gif11.webp";
import collab6 from "../Images/About_Us/docketrun.webp";
import collab1 from "../Images/About_Us/iiit.webp";
// import collab2 from "../Images/About_Us/samsung.webp";
import collab3 from "../Images/About_Us/collab3.webp";
import collab4 from "../Images/About_Us/collab2.webp";
import collab5 from "../Images/About_Us/collab5.webp";
import mutli from "../Images/Home/multimodal_gif.webp";
import three_d from "../Images/Home/3dTeddy.webp";
// import research_projects_1 from "../Images/Landing_images/research_projects_2.webp";
// import research_projects_2 from "../Images/Landing_images/research_projects_1.webp";
// import research_projects_3 from "../Images/Landing_images/research_projects_3.webp";
// import { Home } from "@mui/icons-material";
import Marquee from "../Components/Home/Marquee";
import Cards from "../Components/Projects/Cards.js";
import Cardsp from "../Components/Research/Cards_main.js";
import PublicationsImage1 from "../publication_thumbnails/LGAfford-NET.webp";
import PublicationsImage2 from "../publication_thumbnails/HNN.webp";
import PublicationsImage3 from "../publication_thumbnails/NTIRE.webp";
import ach_1 from "../Images/Achievements/ach_1.webp";
import ach_2 from "../Images/Achievements/ach_2.webp";
import ach_3 from "../Images/Achievements/ach_3.webp";
import ach_4 from "../Images/Achievements/ach_4.webp";
import Sindhu from "../Images/Home/Sindhu.webp";
import Deepti from "../Images/Home/deepti.webp";
import Rohan from "../Images/Home/Rohan.webp";
import cevi_logo from "../Images/CEVI_logo.webp";

// import sponsored from "../Images/Home/Sponsored.webp";
// import Collaborations from "../Images/Home/Collabrative.webp";
// import Consultancy from "../Images/Home/Consultancy.webp";
import CustomButton from "../Pages/Button";
import Footer from "../Pages/Footer.js";

import PublicationCard from "../Components/Publications/PublicationCards.js";

function Home_trial() {
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      if (window.innerWidth > 768) {
        setShowVideo(true);
      } else {
        setShowVideo(false);
      }
    };

    // Check screen size on mount
    checkScreenSize();

    // Listen for window resize
    window.addEventListener("resize", checkScreenSize);

    // Cleanup listener on unmount
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    <div>
      <section className="min-vh-80" style={{ paddingBlockStart: "4em" }}>
        <div className="container ">
          {" "}
          <div>
            <Navbar />
          </div>
          <div className="row mt-4 mb-4 pt-3">
            <div className="col-12 col-md-6 ps-3">
              {/* CEVI title to display on mobile screen */}
              <div className="row">
                <div className="col-7 d-md-none">
                  <p className="lh-1 display-4 fw-normal">Center of</p>
                  <p className="lh-1 display-4 fw-normal">Excellence</p>
                  <p className="lh-1 display-4 fw-normal">
                    in <span className="lh-1 display-4 fw-bolder">Visual</span>
                  </p>
                  <p className="lh-1 display-4 fw-bolder">Intelligence</p>
                </div>
                {/* <div className="col-1"></div> */}
                <div className="col-5 d-md-none d-flex justify-content-end">
                  <img
                    src={cevi_logo}
                    className="img-fluid object-fit-contain align-self-start"
                    width="130px"
                    alt="CEVI logo"
                  />
                </div>
              </div>
              {/* CEVI title to display on bigger screens */}
              <div className="d-none d-md-block col-md-12">
                <p
                  className="lh-1 fw-normal mt-4"
                  style={{ fontSize: "calc(2.2rem + 0.2vw)" }}
                >
                  Center of Excellence in
                </p>
                <p
                  className="lh-1 fw-bolder"
                  style={{ fontSize: "calc(2.2rem + 0.2vw)" }}
                >
                  Visual Intelligence
                </p>
              </div>
              <p className="lh-sm text-start mt-4 content-font-size">
                To be pioneers in visual intelligence by providing innovative
                environment, capture capabilities, outstanding research
                experience, global recognition, and research excellence with an
                impact on the industry and society.
              </p>
              <Link
                to="../contact"
                style={{ textDecoration: "none", color: "black" }}
              >
                <CustomButton title="Contact Us" id="contactUs" />
              </Link>
            </div>
            {showVideo ? (
              <div className="col-6 overflow-hidden text-center yt-embed-holder pt-3">
                <video
                  width="80%"
                  autoPlay
                  muted
                  disablePictureInPicture
                  id=""
                  className="rounded-4"
                >
                  <source src={output_2} type="video/mp4" />
                </video>
                {/* <iframe src='https://my.spline.design/untitled-8dd9b91a3de524e6b401887956873f96/' frameborder='0' width='100%' height='100%'></iframe> */}
                {/* <iframe src='https://my.spline.design/darkroom-14af5d0dd136641892e66e0f63782c6e/' frameborder='0' width='100%' height='100%'></iframe> */}
                {/* <iframe
                width="560"
                height="315"
                src="https://www.youtube-nocookie.com/embed/LTJuFmjvjtA?si=9_HHTd6qciSPUvBF&controls=0&autoplay=1&mute=1&loop=1&modestbranding=1&rel=0&enablejsapi=1&playsinline=1&enablejsapi=1&playlist=LTJuFmjvjtA"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; web-share;"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
                className="rounded-5"
              ></iframe> */}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="container d-none d-sm-block">
          <div style={{ paddingBlock: "2em" }}>
            <Marquee
              imgs={[
                {
                  img: `${gif1}`,
                  text: "Internship",
                },
                {
                  img: `${gif2}`,
                  text: "3D Room",
                },

                {
                  img: `${gif9}`,
                  text: "Underwater",
                },
                {
                  img: `${gif5}`,
                  text: "Annotation",
                },
                {
                  img: `${gif3}`,
                  text: "Annotation",
                },
                {
                  img: `${gif4}`,
                  text: "Annotation",
                },
                {
                  img: `${gif10}`,
                  text: "Dark Room",
                },
                {
                  img: `${gif11}`,
                  text: "Capture Setup",
                },
              ]}
            />
          </div>
        </div>

        <div
          id="carouselMobile"
          className="carousel slide d-sm-none p-4 mt-3"
          data-bs-ride="carousel"
        >
          {/* <div className="carousel-indicators" style={{ bottom: "-1.5rem" }}>
            <button
              type="button"
              data-bs-target="#carouselMobile"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselMobile"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselMobile"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselMobile"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselMobile"
              data-bs-slide-to="4"
              aria-label="Slide 5"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselMobile"
              data-bs-slide-to="5"
              aria-label="Slide 6"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselMobile"
              data-bs-slide-to="6"
              aria-label="Slide 7"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselMobile"
              data-bs-slide-to="7"
              aria-label="Slide 8"
            ></button>
          </div> */}
          <div className="carousel-inner rounded-4">
            <div className="carousel-item active">
              <img src={gif1} className="d-block w-100" alt="Internship" />
            </div>
            <div className="carousel-item">
              <img src={gif2} className="d-block w-100" alt="3D room" />
            </div>
            <div className="carousel-item">
              <img src={gif9} className="d-block w-100" alt="Underwater" />
            </div>
            <div className="carousel-item">
              <img src={gif5} className="d-block w-100" alt="Annotation" />
            </div>
            <div className="carousel-item">
              <img src={gif3} className="d-block w-100" alt="Annotation" />
            </div>
            <div className="carousel-item">
              <img src={gif4} className="d-block w-100" alt="Annotation" />
            </div>
            <div className="carousel-item">
              <img src={gif10} className="d-block w-100" alt="Dark Room" />
            </div>
            <div className="carousel-item">
              <img src={gif11} className="d-block w-100" alt="Capture Setup" />
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselMobile"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselMobile"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </section>

      <section className="container" style={{ paddingBlock: "2em" }}>
        {/* Collaborations and Institutions */}
        <div className="text-center fw-bold mb-5 heading-font-size">
          Collaborations with Institutions
        </div>
        <div className="container">
          <div className="row gy-5 justify-content-center">
            <div className="col-sm-2 col-6 d-flex align-items-center justify-content-center">
              <Link
                to="https://www.iiit.ac.in/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={collab1} alt="iiit" className="img-fluid" />
              </Link>
            </div>

            <div className="col-sm-2 col-6 d-flex align-items-center justify-content-center">
              <Link
                to="https://docketrun.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="img-fluid" src={collab6} alt="docketrun" />
              </Link>
            </div>
            <div className="col-sm-2 col-6 d-flex align-items-center justify-content-center">
              <Link
                to="https://home.iitd.ac.in/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="img-fluid" src={collab3} alt="iitd" />
              </Link>
            </div>
            <div className="col-sm-2 col-6 d-flex align-items-center justify-content-center">
              <Link
                to="https://kledental-bgm.edu.in/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="img-fluid" src={collab4} alt="kledental" />
              </Link>
            </div>
            <div className="col-sm-2 col-6 d-flex align-items-center justify-content-center">
              <Link
                to="https://www.aiimsexams.ac.in/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="img-fluid" src={collab5} alt="aiims" />
              </Link>
            </div>
            {/* <div className="col-sm-2 col-6 d-flex align-items-center justify-content-center">
              <Link
              // to="collaborations_6" target="_blank" rel="noopener noreferrer"
              >
                <img className="img-fluid" src={collab2} alt="collab2" />
              </Link>
            </div> */}
          </div>
        </div>
      </section>

      <section className="container text-dark" style={{ paddingBlock: "2em" }}>
        <div className="text-center fw-bold mb-5 heading-font-size">
          Research Focus
        </div>
        <div className="row gy-5">
          <div className="col-lg-4 col-md-6">
            <Cardsp
              img={research3}
              title="2D Data Acquisition and Processing"
              id="01"
              link="/research/2d"
              desc="Our research extensively harnesses the capabilities of deep generative models, not only for image enhancement and denoising but also for data augmentation, thereby significantly refining the interpretability and quality of images captured in low-light conditions. "
            />
          </div>

          <div className="col-lg-4 col-md-6">
            <Cardsp
              img={three_d}
              title="3D Data Acquisition and Processing"
              id="02"
              link="/research/3d"
              desc="Our current research focus is on 3D data acquisition and processing is dedicated to enhancing machines' ability to perceive, analyze, and interact with the 3D world. Ultimately, our aim is to empower machines with a deeper understanding of the 3D visual data they encounter."
            />
          </div>

          <div className="col-lg-4 col-md-6">
            <Cardsp
              img={mutli}
              title="Multimodal Data Acquisition and Processing"
              id="03"
              // link="/research/multimodal"
              desc="This research aims to develop AI models that can simultaneously process and comprehend visual and textual information, enabling machines to glean richer insights from multimedia content. Applications span a wide spectrum, where the synergy of vision and text enhances data interpretation and decision-making."
            />
          </div>
        </div>
      </section>
      <section
        className="container text-dark"
        style={{ paddingBlock: "2em", backgroundColor: "" }}
      >
        <div className="text-center fw-bold mb-5 heading-font-size">
          Research Projects
        </div>
        <div className="row gy-5">
          <div className="col-lg col-md-6 ">
            <Cards
              img={research_projects_1}
              title="Sponsored Research Projects"
              id="01"
              link="/projects/sponsored"
              // desc="At our research center, we specialize in the exciting field of 2D data acquisition and processing. At our research center, we specialize in the exciting field of 2D data acquisition and processing."
            />
          </div>
          <div className="col-lg col-md-6 ">
            <Cards
              title="Collaborative Research Projects"
              img={research_projects_2}
              id="02"
              // link="/research/3d"
              // desc="At our research center, we specialize in the fascinating field of 3D data acquisition and processing. At our research center, we specialize in the fascinating field of 3D data acquisition and processing."
            />
          </div>

          <div className="col-lg mx-auto col-md-6">
            <Cards
              img={research_projects_3}
              title="Consultancy Projects"
              id="03"
              link="/projects/Consultancy"
              // desc="At our research center, we specialize in the exciting field of multimodal acquisition and processing. At our research center, we specialize in the exciting field of multimodal acquisition and processing."
            />
          </div>
        </div>
      </section>

      <section className="text-dark mb-5" style={{ paddingBlock: "2em" }}>
        <div className="text-center fw-bold mb-5 heading-font-size">
          Recent Publications
        </div>
        <div className="container">
          <div className="">
            <PublicationCard
              // key={index}
              image={PublicationsImage1}
              title="LGAfford-Net: A Local Geometry Aware Affordance Detection Network for 3D Point Clouds"
              org="CVPR-W June - 2024"
              authors="Ramesh Ashok Tabib, Dikshit Hegde, Uma Mudenagudi"
              // link={t[8]}
              pdf="https://openaccess.thecvf.com/content/CVPR2024W/DLGC/html/Tabib_LGAfford-Net_A_Local_Geometry_Aware_Affordance_Detection_Network_for_3D_CVPRW_2024_paper.html"
              // code="null"
              // video="null"
              // type={t[6]}
              // topics={t[9]}
            />
            <PublicationCard
              // key={index}
              image={PublicationsImage2}
              title="HNN: Hierarchical Noise-Deinterlace Net Towards Image Denoising"
              org="CVPR-W, June - 2024"
              authors="Amogh Joshi, Nikhil Akalwadi, Chinmayee Mandi, Chaitra Desai, Ramesh Ashok Tabib, Ujwala Patil, Uma Mudenagudi"
              // link={t[8]}
              pdf="https://openaccess.thecvf.com/content/CVPR2024W/PBVS/html/Joshi_HNN_Hierarchical_Noise-Deinterlace_Net_Towards_Image_Denoising_CVPRW_2024_paper.html"
              // code="null"
              // video="null"
              // type={t[6]}
              // topics={t[9]}
            />
            <PublicationCard
              // key={index}
              image={PublicationsImage3}
              title="NTIRE 2024 image shadow removal challenge report"
              org="CVPR-W, June - 2024"
              authors="Amogh Joshi, Nikhil Akalwadi, Chinmayee Mandi, Chaitra Desai, Ramesh Ashok Tabib, Ujwala Patil, Uma Mudenagudi"
              // link={t[8]}
              pdf="https://openaccess.thecvf.com/content/CVPR2024W/NTIRE/html/Ren_The_Ninth_NTIRE_2024_Efficient_Super-Resolution_Challenge_Report_CVPRW_2024_paper.html"
              // code="null"
              // video="null"
              // type={t[6]}
              // topics={t[9]}
            />
            {/* <HorizontalCard
              title="ASUR3D: Arbitrary Scale Upsampling and Refinement of 3D Point Clouds using Local Occupancy Fields"
              author="Akash Kumbar, Tejas Anvekar, Ramesh Ashok Tabib, Uma Mudenagudi"
              time="e-Heritage | ICCVW, Oct - 2023"
              imageSrc={PublicationsImage3}
              pdfLink="https://openaccess.thecvf.com/content/ICCV2023W/e-Heritage/html/Kumbar_ASUR3D_Arbitrary_Scale_Upsampling_and_Refinement_of_3D_Point_Clouds_ICCVW_2023_paper.html"
              codeLink="null"
              videoLink="null"
            /> */}
          </div>
        </div>
      </section>
      <section
        className="text-dark"
        style={{
          backgroundColor: "#DADEE3",
          paddingBlock: "2em",
        }}
      >
        <div className="container heading-font-size text-center fw-bold">
          Our Legacy of Excellence
        </div>

        <div className="container text-break text-center">
          <span className="content-font-size">
            <br />
            We take pride in fostering growth and innovation through
            groundbreaking research, impactful projects, and nurturing the next
            generation of leaders. Our journey is defined by achievements that
            continue to inspire and drive change.
          </span>
        </div>

        <div className="container">
          <div className="row pt-4 text-center d-flex justify-content-center ">
            <div className="col-6 col-sm-6 col-md-2 mt-3 flex-fill">
              <b>
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "calc(2.0rem + 0.2vw)",
                    color: "#0870D8",
                  }}
                >
                  5+
                </span>
                <br />
                <p className="content-font-size">startups</p>
              </b>
            </div>
            <div className="col-6 col-sm-6 col-md-2 mt-3 flex-fill">
              <b>
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "calc(2.0rem + 0.2vw)",
                    color: "#0870D8",
                  }}
                >
                  100+
                </span>
                <br />
                <p className="content-font-size"> publications</p>
              </b>
            </div>
            <div className="col-6 col-sm-6 col-md-2 mt-3 flex-fill">
              <b>
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "calc(2.0rem + 0.2vw)",
                    color: "#0870D8",
                  }}
                >
                  13+
                </span>
                <br />
                <p className="content-font-size"> projects</p>
              </b>
            </div>
            <div className="col-6 col-sm-6 col-md-2 mt-3 flex-fill">
              <b>
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "calc(2.0rem + 0.2vw)",
                    color: "#0870D8",
                  }}
                >
                  1000+
                </span>
                <br />
                <p className="content-font-size">Students Trained</p>
              </b>
            </div>
          </div>
        </div>
      </section>
      <section className="text-dark mt-5" style={{ paddingBlock: "2em" }}>
        <div className="text-center fw-bold mb-5 heading-font-size">
          Achievements
        </div>
        <div className="container container-fluid achievements">
          <div className="row text-center d-flex justify-content-center gap-0 row-gap-3 column-gap-3 mb-2">
            <div className="col m-auto">
              <div>
                <img
                  src={ach_1}
                  alt="Achievements_1"
                  style={{ marginTop: "10px", marginBottom: "16px" }}
                />
              </div>
              <div>
                <b>
                  Selected amongst top teams @NTIRE CVPR 2022 Published a Joint
                  Report on Low Light Enhancement
                </b>
              </div>
            </div>
            <div className="col ">
              <div>
                <img
                  src={ach_2}
                  alt="Achievements_2"
                  style={{ marginBottom: "5px" }}
                />
              </div>
              <div>
                <b>
                  Detection of Drone in Wild(CCTV Feeds)- by DRDO Rs 1,00,000
                  Cash Prize
                </b>
              </div>
            </div>
            <div className="col">
              <div>
                <img
                  src={ach_3}
                  alt="Achievements_3"
                  style={{ marginTop: "27px", marginBottom: "37px" }}
                />
              </div>
              <div>
                <b> MaCVi'23 SeaDronesSee Published a Joint Report</b>
              </div>
            </div>
            <div className="col ">
              <div>
                <img
                  src={ach_4}
                  alt="Achievements_4"
                  style={{ marginBottom: "10px" }}
                />
              </div>
              <div>
                <b> Flare Removal Challenge To Publish a Joint Report</b>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="text-dark "
        style={{
          paddingBlock: "2em",
        }}
      >
        {/* Review components for desktop */}

        <div className="container">
          <div className="pb-1 text-center fw-bold mb-5 heading-font-size">
            Testimonials
          </div>
        </div>
        <div className="container d-none d-lg-block">
          {" "}
          <div className="row">
            <div className="col p-3">
              <CarouselItem
                img={Sindhu}
                name="Sindhu"
                title="PhD Scholar at Oxford"
                testimonial="Working with the Visual Intelligence Lab was a transformative experience for me. Their cutting-edge technologies and innovative approaches to image analysis opened up new avenues of creativity in my digital art. The insights and tools they provided have elevated the quality and depth of my work, allowing me to push the boundaries of visual storytelling."
              />
            </div>
            <div className="col p-3">
              <CarouselItem
                img={Deepti}
                name="Deepti"
                title="PhD Scholar at Johns Hoppkins"
                testimonial="Partnering with the Visual Intelligence Lab gave our marketing campaigns a competitive edge. Their expertise in image recognition and analysis enabled us to understand our audience's preferences on a deeper level. This led to more targeted and effective visual con tent, resulting in increased engagement and conversions. The lab's insights are now an integral part of our marketing strategy."
              />
            </div>
            <div className="col  p-3">
              <CarouselItem
                img={Rohan}
                name="Rohan"
                title="PhD Scholar at IIT Delhi"
                testimonial="As a researcher in the field of computer vision, collaborating with the Visual Intelligence Lab was invaluable. Their dedication to pushing the boundaries of image understanding is evident in their advanced algorithms and tools. The lab's resources and expertise have accelerated our progress in solving complex problems and advancing the state of the art in visual intelligence."
              />
            </div>
          </div>
        </div>

        {/* Review components for mobile */}
        <div className="row justify-content-center px-4">
          <div
            id="carouselReviewMobile"
            className="carousel slide d-sm-none col-sm-6 rounded-5 mx-3"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators" style={{ bottom: "-3.5rem" }}>
              <button
                type="button"
                data-bs-target="#carouselReviewMobile"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselReviewMobile"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselReviewMobile"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>
            <div className="carousel-inner rounded-4 mt-4">
              <div className="carousel-item active ">
                <CarouselItem
                  img={Sindhu}
                  name="Sindhu"
                  title="PhD Scholar at Oxford"
                  testimonial="Working with the Visual Intelligence Lab was a transformative experience for me. Their cutting-edge technologies and innovative approaches to image analysis opened up new avenues of creativity in my digital art. The insights and tools they provided have elevated the quality and depth of my work, allowing me to push the boundaries of visual storytelling."
                />
              </div>
              <div className="carousel-item">
                <CarouselItem
                  img={Deepti}
                  name="Deepti"
                  title="PhD Scholar at Johns Hoppkins"
                  testimonial="Partnering with the Visual Intelligence Lab gave our marketing campaigns a competitive edge. Their expertise in image recognition and analysis enabled us to understand our audience's preferences on a deeper level. This led to more targeted and effective visual content, resulting in increased engagement and conversions. The lab's insights are now an integral part of our marketing strategy."
                />
              </div>
              <div className="carousel-item">
                <CarouselItem
                  img={Rohan}
                  name="Rohan"
                  title="PhD Scholar at IIT Delhi"
                  testimonial="As a researcher in the field of computer vision, collaborating with the Visual Intelligence Lab was invaluable. Their dedication to pushing the boundaries of image understanding is evident in their advanced algorithms and tools. The lab's resources and expertise have accelerated our progress in solving complex problems and advancing the state of the art in visual intelligence."
                />
              </div>
            </div>
          </div>
        </div>

        {/* Review components for tablet */}

        <div
          id="carouselTablet"
          className="carousel slide d-none d-sm-block d-lg-none px-5"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselTablet"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselTablet"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselTablet"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div className="carousel-inner rounded-4">
            <div className="carousel-item active">
              <div className="d-flex">
                <div className="row">
                  <div className="col-6">
                    <CarouselItem
                      img={Sindhu}
                      name="Sindhu"
                      title="PhD Scholar at Oxford"
                      testimonial="Working with the Visual Intelligence Lab was a transformative experience for me. Their cutting-edge technologies and innovative approaches to image analysis opened up new avenues of creativity in my digital art. The insights and tools they provided have elevated the quality and depth of my work, allowing me to push the boundaries of visual storytelling."
                    />
                  </div>
                  <div className="col-6">
                    <CarouselItem
                      img={Deepti}
                      name="Deepti"
                      title="PhD Scholar at Johns Hoppkins"
                      testimonial="Partnering with the Visual Intelligence Lab gave our marketing campaigns a competitive edge. Their expertise in image recognition and analysis enabled us to understand our audience's preferences on a deeper level. This led to more targeted and effective visual content, resulting in increased engagement and conversions. The lab's insights are now an integral part of our marketing strategy."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="d-flex">
                <div className="row">
                  <div className="col-6">
                    <CarouselItem
                      img={Deepti}
                      name="Deepti"
                      title="PhD Scholar at Johns Hoppkins"
                      testimonial="Partnering with the Visual Intelligence Lab gave our marketing campaigns a competitive edge. Their expertise in image recognition and analysis enabled us to understand our audience's preferences on a deeper level. This led to more targeted and effective visual content, resulting in increased engagement and conversions. The lab's insights are now an integral part of our marketing strategy."
                    />
                  </div>
                  <div className="col-6">
                    <CarouselItem
                      img={Rohan}
                      name="Rohan"
                      title="PhD Scholar at IIT Delhi"
                      testimonial="As a researcher in the field of computer vision, collaborating with the Visual Intelligence Lab was invaluable. Their dedication to pushing the boundaries of image understanding is evident in their advanced algorithms and tools. The lab's resources and expertise have accelerated our progress in solving complex problems and advancing the state of the art in visual intelligence."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="d-flex">
                <div className="row">
                  <div className="col-6">
                    <CarouselItem
                      img={Rohan}
                      name="Rohan"
                      title="PhD Scholar at IIT Delhi"
                      testimonial="As a researcher in the field of computer vision, collaborating with the Visual Intelligence Lab was invaluable. Their dedication to pushing the boundaries of image understanding is evident in their advanced algorithms and tools. The lab's resources and expertise have accelerated our progress in solving complex problems and advancing the state of the art in visual intelligence."
                    />
                  </div>
                  <div className="col-6">
                    <CarouselItem
                      img={Sindhu}
                      name="Sindhu"
                      title="PhD Scholar at Oxford"
                      testimonial="Working with the Visual Intelligence Lab was a transformative experience for me. Their cutting-edge technologies and innovative approaches to image analysis opened up new avenues of creativity in my digital art. The insights and tools they provided have elevated the quality and depth of my work, allowing me to push the boundaries of visual storytelling."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="mt-5">
        <Footer />
      </div>
    </div>
  );
}
export default Home_trial;
