// import React, {useEffect, useRef } from "react";
import React from "react";
import Backtotop from "../Components/Common/BackToTop";
// import projects_background from "../Images/About_Us/about-background.webp";
import "../Stylesheets/Projects.css";
import Navbar from "../Components/Common/Navbar";
// import sponsor from "../Images/Home/Sponsored.webp";
// import collaborative from "../Images/Home/Collabrative.webp";
// import consultancy from "../Images/Home/Consultancy.webp";
// import academic from "../Images/Projects/academic.webp";
import Cards from "../Components/Projects/Cards.js";
import Footer from "../Pages/Footer.js";
import research_projects_1 from "../Images/Projects/SponsoredImage.webp";
import research_projects_2 from "../Images/Projects/CollaborativeImage.webp";
import research_projects_3 from "../Images/Projects/ConsultancyImage.webp"
import topbg from "../Images/About_Us/Bannera.webp";
// import { Link } from "react-router-dom";
// import Breadcrumbs from "../Components/Common/Breadcrumbs";
import Hero from "../Components/Common/Hero.js";

function Projects() {
  return (
    <>
      <div className="min-vh-100">
        <Backtotop />

        <div className="background-projects">
          <Navbar />
          <br />
          <br />
          <br />
          {/* <Breadcrumbs /> */}

          <Hero title="Projects" img={topbg} 
          creatorLink="https://unsplash.com/@pawel_czerwinski?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          creator="Pawel Czerwinski"
          imageLink="https://unsplash.com/photos/an-abstract-pink-background-with-wavy-lines-bloGOumd0Z4?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          source="Unsplash"
          />
        </div>

        <br />
      
        <div className="container">
          <div className="mt-5">
            <div className="row gy-5">
              <div className="col-lg col-md-6">
                <Cards
                  img={research_projects_1}
                  title="Sponsored Research Projects"
                  id="01"
                  link="/projects/sponsored"
                  // desc="At our research center, we specialize in the exciting field of 2D data acquisition and processing. At our research center, we specialize in the exciting field of 2D data acquisition and processing."
                />
              </div>
              <div className="col-lg col-md-6 ">
                <Cards
                  title="Collaborative Research Projects"
                  img={research_projects_2}
                  id="02"
                  link=""
                  // desc="At our research center, we specialize in the fascinating field of 3D data acquisition and processing. At our research center, we specialize in the fascinating field of 3D data acquisition and processing."
                />
              </div>

              <div className="col-lg mx-auto col-md-6">
                <Cards
                  img={research_projects_3}
                  title="Consultancy Projects"
                  id="03"
                  link="/projects/Consultancy"
                  // desc="At our research center, we specialize in the exciting field of multimodal acquisition and processing. At our research center, we specialize in the exciting field of multimodal acquisition and processing."
                />
              </div>
            </div>
          </div>

          {/* <div className="d-flex row justify-content-center">
          <div className="col-lg col-md-6 p-0 mb-5">
              <Cards
                img={research_projects_2}
                title="Other Projects"
                id="03"
                // link="/research/multimodal"
                // desc="Projects that have been completed with the help of a consultant."
              />
            </div>
            <div className="col-lg col-md-6 p-0 mb-5">
              <Cards
                img={research_projects_2}
                title="Other Projects"
                id="03"
                // link="/research/multimodal"
                // desc="Projects that have been completed with the help of a consultant."
              />
            </div>
            <div className="col-lg col-md-6 p-0 mb-5">
              <Cards
                img={research_projects_2}
                title="Other Projects"
                id="03"
                // link="/research/multimodal"
                // desc="Projects that have been completed with the help of a consultant."
              />
            </div>

            </div> */}
        </div>
        <br />
        <br />
        <br />
        <br />
   
       
        <Footer />
      </div>
    </>
  );
}

export default Projects;
