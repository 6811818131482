import React from "react";
import { TypeAnimation } from "react-type-animation";
import "../../Stylesheets/Hero.css";

function Hero(props) {
  const showAnimation = props.showAnimationText !== "false";
  return (
    <div
      className="hero hero-banner-height overflow-hidden"
      id=""
      style={{
        minHeight: "30vh",
        backgroundImage: `url('${props.img}')`,
        backgroundSize: "cover",
      }}
    >
      <div className="container">
        {/* <div className="container "></div> */}
        <div
          className="d-flex justify-content-start text-light align-items-center hero-title-font-size"
          style={{
            fontWeight: 500,
          }}
        >
          {props.title}
        </div>
        <span
          className="text-light  hero-subtitle-font-size"
          style={{
            fontWeight: 500,
          }}
        >
          {props.subTitle}
        </span>
        {showAnimation ? (
          <div>
            <TypeAnimation
              sequence={[
                "#Empowering Innovation through Visual Perception",
                500,
                "#Redefining Possibilities with Visual Intelligence",
                500,
                "#Advancing Visual Intelligence through Innovation",
                500,
                "#Where pixels inspires brilliance",
                500,
              ]}
              style={{
                fontSize: "1.352rem",
                color: "white",
                display: "none",
              }}
              className="d-md-block"
              speed={50}
              omitDeletionAnimation={true}
              repeat={Infinity}
            />
          </div>
        ) : (
          <div style={{ fontSize: "1.352rem" }}></div>
        )}
      </div>
      <div className="text-end mt-5 me-3 text-light hero-caption-font-size">
        Photo by{" "}
        <a
          className="text-light"
          href={props.creatorLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.creator}
        </a>{" "}
        on{" "}
        <a
          className="text-light"
          href={props.imageLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.source}
        </a>
      </div>
    </div>
  );
}

export default Hero;
