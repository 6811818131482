import React from "react";
import Navbar from "../Components/Common/Navbar";
import about_background from "../Images/2D/Hero.webp";
import img1 from "../Images/3D/Learning Representation of 3D Data.webp";
import "../Stylesheets/Publications.css";
import BackToTop from "../Components/Common/BackToTop";
import Footer from "../Pages/Footer.js";
import Breadcrumbs from "../Components/Common/Breadcrumbs";

import Hero from "../Components/Common/Hero.js";

import ReadMore from "../Components/Common/ReadMore.js";
import PublicationsFetch from "../Components/Publications/PublicationsFetchFunction.js";

function ThreeDimensionalPage3() {
  return (
    <>
      <div className="min-vh-100" style={{ backgroundColor: "#F6F4F2" }}>
        <BackToTop />
        <Navbar />
        <br />
        <br />
        <br />

        <Hero
          title="Learning Representation of 3D Data"
          subTitle=""
          img={about_background}
          showAnimationText="false"
          creatorLink="https://unsplash.com/@pawel_czerwinski?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          creator="Pawel Czerwinski"
          imageLink="https://unsplash.com/photos/a-computer-generated-image-of-a-pink-and-purple-wave-7FqOISWr5V0?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          source="Unsplash"
        />

        <br />
        <div
          className="container"
          style={{ paddingBlock: "1em", marginTop: "1em" }}
        >
          <Breadcrumbs />
          <div className="row">
            <div className="container content-font-size">
              <ReadMore>
                We put a strong emphasis on the development of methods tailored
                to represent 3D data in formats that are highly compatible with
                machine learning and deep learning models. This intricate
                process involves the generation of feature vectors or
                embeddings, carefully designed to encapsulate essential
                information about 3D objects or scenes. These representations
                play a pivotal role in enabling the effective analysis and
                interpretation of 3D data. By leveraging machine learning and
                deep learning techniques, we can extract intricate details,
                spatial relationships, and critical attributes from 3D data,
                facilitating tasks such as object recognition, scene
                understanding, and 3D reconstruction. Our work aims to bridge
                the gap between raw 3D data and advanced machine intelligence,
                empowering applications in fields such as robotics, autonomous
                navigation, and medical imaging, where the ability to process
                and understand 3D information is paramount.
              </ReadMore>
            </div>
          </div>
          <br />

          <div className="row">
            <div className="col col-md text-center">
              <img src={img1} className="img-fluid" alt="..." />
            </div>
          </div>
          {/* <div className="row">
              <div className="col-md-6">
                  <p>
                      This is a paragraph. You can add your text here. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
              </div>
              <div className="col-md-6">
                  <img src={img2} className="img-fluid" alt="Your Image"/>
              </div>
          </div> */}
        </div>
        {/* related publications code */}
        <br />
        <br />
        <div
          className="text-center heading-font-size fw-bold"
          style={{
            paddingBlockEnd: "1em",
            marginTop: "1em",
          }}
        >
          Related Publications
        </div>
        <PublicationsFetch researchFilter="Learning Representation of 3D Data (LR3D)" />
        {/* related publications code */}
        <Footer />
      </div>
    </>
  );
}

export default ThreeDimensionalPage3;
