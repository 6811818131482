import React from "react";
import Navbar from "../Components/Common/Navbar";
import about_background from "../Images/2D/Hero.webp";
import img1 from "../Images/2D/enhancement1.webp";
import img2 from "../Images/2D/Restoration.webp";
import "../Stylesheets/Publications.css";
import BackToTop from "../Components/Common/BackToTop";
import Footer from "../Pages/Footer.js";
import Breadcrumbs from "../Components/Common/Breadcrumbs";
import Hero from "../Components/Common/Hero.js";
import "../index.css";

import PublicationsFetch from "../Components/Publications/PublicationsFetchFunction.js";

function TwoDimensionalPage2() {
  return (
    <>
      <div className="min-vh-100">
        <BackToTop />
        <Navbar />
        <br />
        <br />
        <br />

        <Hero
          title="Image Restoration and Enhancement"
          subTitle=""
          img={about_background}
          showAnimationText="false"
          creatorLink="https://unsplash.com/@pawel_czerwinski?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          creator="Pawel Czerwinski"
          imageLink="https://unsplash.com/photos/a-computer-generated-image-of-a-pink-and-purple-wave-7FqOISWr5V0?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
          source="Unsplash"
        />

        <br />
        <div
          className="container"
          style={{ paddingBlock: "1em", marginTop: "1em" }}
        >
          <Breadcrumbs />
          <div className="row">
            <div className="container content-font-size">
              Our research focuses on the extensive domain of image enhancement,
              where we specialize in crafting innovative algorithms and models.
              These tools enhance image quality through techniques such as
              sharpening, color fidelity improvement, and noise reduction,
              resulting in visually appealing and informative images. The
              applications of image enhancement are widespread, spanning medical
              imaging, remote sensing, and digital photography.
            </div>
          </div>
          <br />
          <div className="row p-3">
            <div className="col text-center">
              <img src={img1} className="img-fluid" alt="..." />
            </div>
          </div>
          <br />

          <div className="row">
            <div className="container content-font-size">
              Our primary focus is on advancing image restoration methods,
              involving the development of algorithms and models proficient in
              restoring images to their original, high-quality state by
              addressing diverse distortions such as noise and blurriness. These
              techniques have wide-ranging significance, applicable in domains
              like medical imaging, historical image preservation, and satellite
              imagery enhancement.
            </div>
          </div>
          <br />

          <div className="row p-3">
            <div className="col text-center">
              <img src={img2} className="img-fluid" alt="..." />
            </div>
          </div>
        </div>

        {/* related publications code */}
        <br />
        <br />
        <div
          className="text-center heading-font-size fw-bold"
          style={{
            paddingBlockEnd: "1em",
          }}
        >
          Related Publications
        </div>

        <PublicationsFetch researchFilter="Image Restoration and Enhancement (IRE)" />

        <Footer />
      </div>
    </>
  );
}

export default TwoDimensionalPage2;
